<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>流量组</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/customerCard' }">
                    客户跟进信息
                </el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="detail-info">
            <el-form ref="form" v-loading="formLoading" :model="form" :rules="rules" label-width="140px" size="mini">
                <el-form-item label="备注: " label-position="left" prop="remark">
                    <el-input v-model="form.remark" style="width: 500px" type="textarea"></el-input>
                </el-form-item>

                <el-form-item label="图片: " label-position="left">
                    <paste-image :picArray="form.pics" upload-path="sales" @onDelete="onDelete"    @onSuccess="onSuccess"></paste-image>
                </el-form-item>
                <el-form-item>
                    <el-button :loading="loading" size="small" type="primary" @click="onSubmit">
                        保存
                    </el-button>
                    <el-button size="small" style="margin-left: 10px" @click="$router.back()">
                        取消
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import PasteImage from "@/components/pasteImage/index";
export default {
    name: "Add",
    components: {
        PasteImage,
    },
    data() {
        return {
            loading: false,
            form: {
                pics: [],
            },
            formLoading:false,
            rules: {
                remark: [{ required: true, trigger: "blur", message: "请输入备注" }],
            },
        };
    },
    methods: {
        ...mapActions("customerCard", [
            "addCustomerFollowRecord",
            "getCustomerFollowRecord",
        ]),
        onSuccess(pic) {
            this.form.pics = pic;
        },
        onDelete(pic) {
            this.form.pics = pic;
        },
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.addClass();
                }
            });
        },
        async addClass() {
            this.loading = true;
            const { res_info } = await this.addCustomerFollowRecord(this.form);
            this.loading = false;
            this.$router.back();
            if (res_info != "ok") return;
            this.$message.success("创建成功");
        },
        async init(id) {
            this.formLoading = true;
            const { data } = await this.getCustomerFollowRecord({ id });
            this.form = data;
            this.formLoading = false;
        },
    },
    mounted() {
        this.init(this.$route.params.rollowRecordId);
    },
};
</script>
